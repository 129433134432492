<template>
  <div class="business_menu_grid">
    <div class="header_panel">
      <!-- <sys-header
        :isActiveIn="true"
        ref="sysHeader"
        :effect="scrollTop!==0?'light':'dark'"
      ></sys-header> -->
      <sys-header :isActiveIn="true" ref="sysHeader"></sys-header>
    </div>
    <div class="grid_main_panel">
      <div class="grid_main_content" ref="gridMainContent" @scroll="scrollFn">
        <div class="tab_panel">
          <div class="tab_panel_bg">
            <img :src="modulePic">
          </div>
          <div class="panel_topic_panel">
            <div class="panel_topic_content">
              <span>NEWS CENTER</span>
              <span>新闻资讯</span>
            </div>
          </div>
        </div>
        <div class="comp_panel">
          <!-- <component :is="compName"></component> -->
          <router-view></router-view>
        </div>
        <div class="footer_panel">
          <page-footer></page-footer>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import SysHeader from "../common/sysHeader"
import PageFooter from "../common/pageFooter"
export default {
  name: "compS",
  components: {
    SysHeader,
    PageFooter,
  },
  props:{
    modulePic:{
      type:String,
      default:""
    }
  },
  // 定义属性
  data() {
    return {
      scrollTop: 0,// 滚动条位置
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    compArr() {
      return []
    },
    compName() {
      return this.$route.name
    },
  },
  watch: {
    '$route': {
      handler: function () {
        this.scrollTop = 0
        this.$refs.gridMainContent.scrollTop = 0
      },
      // 设置为深度监听
      deep: true
    }
  },
  // 方法集合
  methods: {
    // 滚动方法
    scrollFn() {
      let _this = this
      _this.scrollTop = _this.$refs.gridMainContent.scrollTop
    },
    // 路由跳转
    goPageFn(name) {
      let _this = this
      _this.$router.push({
        name: name,
      })
    },

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() { },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_menu_grid {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .header_panel {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    background: #ffffff;
    // .header_wrapper_content {
    //   width: 100%;
    //   height: 100px;
    // }
    // &.header_wrapper_active {
    //   height: 100vh;
    //   background: rgba(0, 0, 0, 0.5);
    // }
  }
  .tab_panel {
    width: 100%;
    display: flex;
    // background: url('./images/module@bg.png') no-repeat center center;
    background-size: cover;
    align-items: flex-end;
    position: relative;
    padding-top: 100px;
    .tab_panel_bg{
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      z-index: 1;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .panel_topic_panel {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 1rem 0;
      z-index: 3;
      .panel_topic_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-left: 20px;
        span {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 1.8rem;
          color: #ffffff;
          line-height: 2.4rem;
          &:first-child {
            font-size: 1.8rem;
            color: #ffffff;
            line-height: 2.6rem;
            margin-top: 1.5rem;
          }
        }
      }
    }
    .tab_wrapper {
      display: flex;
      min-width: 1200px;
      max-width: 1520px;
      width: calc(100vw - 5px);
      margin: 0 auto;
      display: flex;
      height: 100%;
      background: #0163b8;
      .mbx_main {
        // flex: 1;
        overflow: hidden;
      }
      .tab_main {
        background: #0163b8;
        display: flex;
        .tab_item {
          overflow: hidden;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFang SC, PingFang SC;
          font-size: 18px;
          color: #ffffff;
          cursor: pointer;
          text-align: center;
          margin: 0 20px;
          position: relative;
          &:first-child {
            margin-left: 60px;
          }
          &.tab_item_active {
            font-weight: 600;
            &::after {
              content: '';
              width: 100%;
              height: 2px;
              position: absolute;
              bottom: 12px;
              left: 0;
              background: #ffffff;
            }
          }
          &:hover {
            font-weight: 600;
            &::after {
              content: '';
              width: 100%;
              height: 2px;
              position: absolute;
              bottom: 12px;
              left: 0;
              background: #ffffff;
            }
          }
        }
      }
    }
  }
  .grid_main_panel {
    flex: 1;
    overflow: hidden;
    .grid_main_content {
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      /* 滚动条样式 */
      &::-webkit-scrollbar {
        width: 4px; /*  设置纵轴（y轴）轴滚动条 */
        height: 4px; /*  设置横轴（x轴）轴滚动条 */
      }
      /* 滚动条滑块（里面小方块） */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #1a406f;
      }
      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        border-radius: 0;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.1);
      }
      .footer_panel {
        width: 100%;
      }
      .comp_panel {
        width: 100%;
      }
      .footer_panel {
        width: 100%;
      }
    }
  }
}
</style>